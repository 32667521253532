<template>
  <div class="image-item-upload-page-box">
    <el-dialog :visible.sync="visible" v-show="!dialogVisible" :close-on-click-modal="false" :show-close="false">
      <div slot="title">上传图片</div>
      <div class="pdg-tb30" style="border-bottom:1px solid #E9EAEB;position:relative;z-index:10;">
        <el-upload
          :action='uploadApi'
          :headers='headers'
          name='image'
          :before-upload="beforeImageUpload"
          :on-change='onChange'
          :file-list='fileList'
          :on-remove="onRemove"
          :disabled='disabled || isDisableUpload'
          :on-error='onError'
          :on-success="onSuccess"
          :limit='limit'
          :list-type ='listType'
          :show-file-list="showFileList"
          accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
        >
          <div slot="trigger" class="wrapper">
            <div><img src="../assets/common/carmer.png" alt="" class="image-w"></div>
            <div>上传图片</div>
            <div v-if="isDisableUpload"  @click="onDisabledImage" style="position:absolute;top:0;left:0px;width:100%;height:100%;border-radius: 6px;"></div>
          </div>
          <div slot="file" slot-scope="{file}" v-loading='file.status === "loading"' v-if="showFileList">
            <div
              class="image-list-item"
              :style="{'background-image':`url(${file.url || file.middle})`}"
            >
            </div>
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="onRemove(file)"
              >

                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
        <div class="mgn-t10 center">备注：支持jpg、png、gif格式，图片大小不超过{{fileSizeLimit}}M，最多支持四张</div>
      </div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button type="primary" plain   @click="onClose">取消</el-button>
        <el-button :loading="btnLoading"  type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
export default {
  name: 'ImageItemUpload',
  data () {
    return {
      btnLoading: false,

      dialogVisible: false,
      dialogImageUrl: '',
      headers: {
        token: getToken()
      },
      fileList:[]
    }
  },
  props:{
    visible:{       // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    limit: {
      type:Number,
      default: 4
    },
    fileSizeLimit: {
      type: Number,
      default: 5
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listType: {
      type: String,
      default: 'picture-card' //text/picture/picture-card
    },
    bucketType: {
      type: Number,
      default: 116
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    imageList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    uploadApi () {
      return `${API.uploadImage}?bucketType=${this.bucketType}`
    },
    isDisableUpload() {
      return this.fileList.length >= this.limit
    }
  },
  methods: {
    ...mapActions(['onUploadImage']),
    onDisabledImage(){
      this.$message.error({message:`最多上传${this.limit}张图片`})
    },
    onClose(){
      this.$emit("onClose",false)
    },
    onSubmit(){
      console.log('------------------- onSubmit -------------------------');
      this.$emit('onSubmit',this.fileList);
      // let Data = {
      //   videoId: this.isDisableUpload ? this.initVideoObj.videoId : this.aliVideoAuthDto.videoId,
      //   categoryId: this.categoryId,
      //   coverUrl: this.upLoadCover,
      //   title: this.title,
      // };
      // this.onUpdateTrainVideo(Data).then(res=>{
      //   this.$emit('onUpload',[res.data||{}]);
      //   this.$message({
      //     message: "视频文件已保存至素材管理",
      //       type: 'success',
      //       duration: 2000
      //   })
      //   this.onClose();
      // })
    },
    handlePictureCardPreview (file) {
        this.dialogImageUrl = file.url || file.middle;
        this.dialogVisible = true;
    },
    beforeImageUpload (file) {
      if(this.fileList.length === this.limit) {
        this.$message.error(`最多上传${this.limit}张图片`)
        return false
      }
      if (file.size > (this.fileSizeLimit*1024*1024)) {
        this.$message.error(`上传头像图片大小不能超过 ${this.fileSizeLimit}MB!`)
        return false
      }
      // let imageSrc = ''
			//  if(window.URL.createObjectURL) {
			// 		imageSrc = window.URL.createObjectURL(file)
			// 	}else	if(window.FileReader){
			// 		const reader = new FileReader()
			// 		reader.onload = (e)=>{
			// 			imageSrc = e.target.result
			// 		}
			// 		reader.readAsDataURL(file)
			// 	}
      // this.uploadFile = {name:file.name,src: imageSrc,key:new Date().getTime(),status:'loading',file:file}
      return true
    },
    onChange (e) {
      // console.log(e)
    },
    onSuccess (res,file,fileList) {
      // this.fileList.splice(index,1)
      this.fileList = fileList
      if (res.code !== 0) {
        this.fileList = [...this.fileList].filter(v => v.uid!==file.uid)
      }
      this.fileList = [...this.fileList].map(v => {
          if (v.response) {
            v = {...v,...v.response.data}
            delete v.response
          }
          return v
        })
      this.$emit('onSuccess',this.fileList)
    },
    onError (e,file,fileList) {
      this.$message.error(e.message)
      console.log('error')
    },
    onRemove (file) {
      this.fileList = [...this.fileList].filter(v => v.uid!==file.uid)
      this.$emit('onSuccess',this.fileList)
    },
    // onUpload () {
    //   this.fileList = [...this.fileList,this.uploadFile]
    //   this.onUploadImage({file:this.uploadFile.file,bucketType:this.bucketType}).then(res => {
    //     this.fileList = [...this.fileList].map(v => {
    //       if (v.key === this.uploadFile.key) {
    //         v.name = res.imageName
    //         v.status = 'success'
    //       }
    //       return v
    //     })
    //   },rea => {
    //     this.fileList = [...this.fileList].map(v => {
    //       if (v.key === this.uploadFile.key) {
    //         v.status = 'failed'
    //       }
    //       return v
    //     })
    //   })
    // }
  },
  watch: {
    imageList (newVal) {
      console.log(newVal)
      if (newVal.length === 0) {
        this.fileList = []
      } else {
        this.fileList = newVal
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang='scss' scoped>
.image-item-upload-page-box ::v-deep {
  .el-dialog__body {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #3D61E3;
  font-size: 12px;
  position: relative;
}
.image-list-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image-w {
  margin-bottom: 15px;
}
.upload-success{
  color: #09bb07;
  background:#E7FCE6;
  border-radius: 6px;
}
.upload-error{
  color: #F6625E;
  background:#FFE8EA;
  border-radius: 6px;
}
.upload-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.center { text-align: center; color: #0f0f0f;}
</style>
