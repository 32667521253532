<template>
  <div class="video-item-upload-page-box">
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false">
      <div slot="title">上传视频</div>
      <div class="pdg-tb30" style="border-bottom:1px solid #E9EAEB;">
        <div class="flex-start vertical-center mgn-t30">
          <div class="flex-start vertical-center flex-direction-col w100v pdg-lr10" style="position:relative;z-index:10;">
            <el-upload
              action=''
              :http-request="onHttpRequest"
              name='video'
              :disabled="isDisableUpload"
              :show-file-list="false"
              v-bind:before-upload="beforeUploadVideo"
              list-type ='picture-card'
            >
            <div style="position:relative;height:100%;">
              <i class="el-icon-error close-wrapper" v-if="aliVideoAuthDto.videoId" title="删除视频" @click.stop.prevent="onDel"></i>
              <img v-if="videoCoverUrl" :src="videoCoverUrl" style="width:100%;height:100%;">
              <div v-else-if="upLoadSuccess" slot="trigger" class="wrapper" :class="{'upload-success':upLoadSuccess}">
                <div><img src="../assets/iconImg/success.png" alt="" class="image-w"></div>
                <div>上传成功</div>
              </div>
              <div v-else-if="upLoading" slot="trigger" class="wrapper">
                <div class="pdg-b15"><el-progress type="circle" :percentage="percent" :width="50"></el-progress></div>
                <div>上传中...</div>
              </div>
              <div v-else-if="upLoadError" slot="trigger" class="wrapper" :class="{'upload-error':upLoadError}">
                <div><img src="../assets/iconImg/fail.png" alt="" class="image-w"></div>
                <div>上传失败</div>
              </div>
              <div v-else slot="trigger" class="wrapper">
                <div><img src="../assets/common/carmer.png" alt="" class="image-w"></div>
                <div>上传视频</div>
              </div>
              <div v-if="isDisableUpload"  @click="onDisabledVideo" style="position:absolute;top:0;left:10px;width:160px;height:90px;border-radius: 6px;"></div>
            </div>
            </el-upload>
            <div class="mgn-t10 color0f">建议上传30秒以内短视频</div>
          </div>
        </div>
      </div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="btnLoading"  type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from '@/apis/api-types';
import { getToken } from '@/utils/auth'
import { mapActions } from 'vuex';

export default {
  name: "VideoItemUpload",
  data(){
    return {
      headers: {
        token: getToken()
      },
      // -------------------- submit ----------------
      btnLoading: false,
      videoCoverUrl:"",
      customCoverUrl:'',
      upLoadCover:"",
      title:"",
      categoryId:"",
      // ---------------------- upLoad ------------------
      uploader: null,
      aliVideoAuthDto:{},

      percent: 0,
      upLoading: false,
      upLoaded: false,
      upLoadSuccess: false,
      upLoadError: false,
    }
  },
  props:{
    visible:{       // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    isDisableUpload:{
      type: Boolean,
      default: false,
    },
    videoList:{
      type: Array,
      default () {
        return []
      }
    },
    videoTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    uploadApi () {
      return `${API.uploadImage}?bucketType=104`
    },
    videoId () {
      let obj = [...this.videoList][0] || {}
      return obj.videoId || ''
    }
  },
  watch:{
    visible(newVal,oldVal){
      if(newVal&&!this.isDisableUpload){
        this.initPage();
        this.initUploader();
        if (!this.videoId) return
        this.onGetTrainVideoDetail({videoId:this.videoId}).then(res => {
          this.title = res.data.title || '';
          this.videoCoverUrl = res.data.coverUrl || '';
          this.aliVideoAuthDto.videoId = this.videoId
          this.upLoadSuccess = true
          this.customCoverUrl = res.data.customCoverUrl || '';
          this.categoryId = res.data.category&&res.data.category.categoryId || '';
          this.upLoadCover = res.data.fileKey || '';
        })
      }
    }
  },
  mounted(){
    this.initUploader();
  },
  methods:{
    ...mapActions(['onUploadVideo','onGetTrainVideoDetail','onUpdateTrainVideo']),
    onClose(){
      this.$emit("onClose",false)
    },
    onSubmit(){
      console.log('------------------- onSubmit -------------------------');
      if (this.upLoading) return this.$message.error('视频上传中，请稍后')
      if (!this.aliVideoAuthDto.videoId)  {
        this.$emit('onSubmit',[]);
        return
      }
      let req = {
        videoId: this.aliVideoAuthDto.videoId,
        title: this.videoTitle,
        categoryId: '',
        coverUrl:  ''
      }
      this.onUpdateTrainVideo(req).then(res=>{
        this.$emit('onSubmit',[res.data]);
        // this.$message({
        //   message: "视频文件已保存至素材管理",
        //     type: 'success',
        //     duration: 2000
        // })
        this.onClose();
      })
    },
    initPage(){
      this.title = '';
      this.videoCoverUrl = '';
      this.customCoverUrl = '';
      this.categoryId = '';

      this.btnLoading = false;
      this.aliVideoAuthDto ={};

      this.percent = 0;
      this.upLoading = false;
      this.upLoaded = false;
      this.upLoadSuccess = false;
      this.upLoadError = false;
    },
    initUploader(){
      this.uploader = null;
      let _this = this;
      let AliyunUpload = window.AliyunUpload
      this.uploader = new AliyunUpload.Vod({
        // 文件上传失败
        'onUploadFailed': function(uploadInfo, code, message) {
          _this.upLoading = false;
          _this.upLoaded = true;
          _this.upLoadSuccess = false;
          _this.upLoadError = true;
          _this.$message.error(message||'上传失败，请稍后再试')
          console.log(message)

        },
        // 文件上传完成
        'onUploadSucceed': function(uploadInfo) {
          _this.upLoading = false;
          _this.upLoaded = true;
          _this.upLoadSuccess = true;
          _this.upLoadError = false;
          _this.$message.success('上传成功');
          console.log("onUploadSucceed: " + uploadInfo.file.name + ", endpoint:" + uploadInfo.endpoint + ", bucket:" + uploadInfo.bucket + ", object:" + uploadInfo.object);
        },
        // 文件上传进度
        'onUploadProgress': function(uploadInfo, totalSize, percent) {
          _this.upLoading = true;
          _this.upLoaded = false;
          _this.upLoadSuccess = false;
          _this.upLoadError = false;
          _this.percent = Math.floor(percent * 100);
        },
        // STS临时账号会过期，过期时触发函数
        'onUploadTokenExpired': function() {
          _this.$message.success('上传凭证过期，请重试');
        },
        // 开始上传
        'onUploadstarted': function(uploadInfo) {
          if (!_this.aliVideoAuthDto.uploadAuth) return;
          _this.percent = 0;
          _this.upLoading = true;
          _this.upLoaded = false;
          _this.upLoadSuccess = false;
          _this.upLoadError = false;
          _this.uploader.setUploadAuthAndAddress(uploadInfo, _this.aliVideoAuthDto.uploadAuth, _this.aliVideoAuthDto.uploadAddress);
        }
      });
      this.uploader.init();
    },

    handleAvatarSuccess(res, file) {
      this.customCoverUrl = URL.createObjectURL(file.raw);
      this.upLoadCover = res.data.imageName || '';
    },
    onDisabledVideo(){
      // if(!this.title){
      //   this.$message.error({message:"请先填写视频名称！"})
      //   return false;
      // }
      this.$message.error({message:"视频内容不可编辑！"})
    },
    doUpload() {
      this.uploader.startUpload();
    },
    onHttpRequest(){
      // 覆盖默认行为
    },
    onDel () {
      this.initPage()
    },
    //上传前回调
    beforeUploadVideo(file) {
      console.log(file,"---------------- beforeUploadVideo --------------");
      if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
          this.$message.error("请上传正确的视频格式");
          return false;
      }
      // var fileSize = file.size / 1024 / 1024 > 300;
      // if (fileSize) {
      //     this.$message.error("视频大小不能超过300MB");
      //     return false;
      // }
      let userData = '{"Vod":{"UserData":{"IsShowWaterMark":"false","Priority":"7"}}}';
      this.uploader.addFile(file, null, null, null, userData);
      this.onUploadVideo({fileName: file.name,title:this.title||'调研视频'}).then(res => {
        console.log(res,'---------------- onUploadVideo -------------');
        this.aliVideoAuthDto = res.data || {};
        this.doUpload();
      })

    },
    //进度条
    uploadVideoProcess(event, file, fileList) {
        this.videoFlag = true;
        this.videoUploadPercent = file.percentage.toFixed(0) * 1;
    },
  }
}
</script>

<style>
.video-item-upload-page-box .el-dialog{
  width: 500px;
}
.video-item-upload-page-box .el-dialog__body{
  padding:0px;
}
.video-item-upload-page-box .el-upload--picture-card{
  width: 160px;
  height: 90px;
}
</style>
<style scoped>
.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #3D61E3;
  font-size: 12px;
  position: relative;
}
.image-w {
  margin-bottom: 15px;
}
.upload-success{
  color: #09bb07;
  background:#E7FCE6;
  border-radius: 6px;
}
.upload-error{
  color: #F6625E;
  background:#FFE8EA;
  border-radius: 6px;
}
.close-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate3d(50%,-50%,0);
  z-index: 500;
}
.color0f { color: #0f0f0f;}
</style>
