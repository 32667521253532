<!--
 * @Author: your name
 * @Date: 2021-01-29 13:58:02
 * @LastEditTime: 2021-02-01 10:38:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/VideoPlayModal.vue
-->
<template>
  <el-dialog :title="title" width="40%" :visible.sync="visible" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" destroy-on-close>
    <video controls class="video-item">
      <source v-if="visible" :src="videoUrl" type="video/mp4" />
      <source v-if="visible" :src="videoUrl" type="video/webm" />
      <p>Your browser doesn't support HTML5 video. Here is a <a href="myVideo.mp4">link to the video</a> instead.</p>
    </video>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose" size="medium" class="btn-width">关闭 </el-button>
      <el-button v-if="isDown" type="primary" @click="onSubmit" size="medium" class="btn-width">下载视频</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { saveAs } from 'file-saver'
export default {
  name: 'VideoPlayModal',
  data() {
    return {}
  },
  props: {
    videoUrl: String,
    visible: Boolean,
    title: {
      type: String,
      default: '直播视频',
    },
    isDown: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClose() {
      this.$emit('onClose')
    },
    onSubmit() {
      saveAs(this.videoUrl)
    },
  },
}
</script>
<style lang="scss" scoped>
.video-item {
  width: 100%;
}

.dialog-footer {
  display: flex;
  justify-content: center;
}

.btn-width {
  min-width: 120px;
}
</style>
