<template>
  <el-dialog
    destroy-on-close
    class="video-viewer customize-el-dialog"
    title="视频预览"
    width="560px"
    :visible.sync="dialogVisible"
  >
    <div class="video-item">
      <LivePlayer v-if="dialogVisible" type="playback" :video-url="videoUrl" />
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'VideoViewer',
  props: {
    value: Boolean,
    videoUrl: Object
  },
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.video-viewer {
  .video-item {
    width: 100%;
    height: 300px;
    @media screen and (max-width: 768px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
