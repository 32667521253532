<!--
 * @Author: your name
 * @Date: 2021-05-14 16:58:12
 * @LastEditTime: 2021-05-18 13:44:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/SheetToJson.vue
-->
<template>
    <el-upload v-loading="loading" element-loading-target=".el-upload-loading.el-upload" class="el-upload-loading" element-loading-text="文件上传中" :accept="accept" action="" :http-request="onHttpRequest" :show-file-list="false" :before-upload="onBeforeUpload">
        <slot></slot>
    </el-upload>
</template>
<script>
import { excelToJson } from '@/utils/util'
export default {
    name: 'UploadSheetToJson',
    data() {
        return {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx, .xls',
            loading: false
        }
    },
    props: {
        start: {
            type: String,
            default: ''
        },
    },
    methods: {
        onHttpRequest(p) {},
        onBeforeUpload(file) {
            try {
                this.loading = true
                const reader = new FileReader()
                reader.readAsBinaryString(file)
                reader.onload = e => {
                    setTimeout(() => {
                        excelToJson(e.target.result,res => {
                            this.$emit('onUpload', res)
                            this.loading = false
                            return { json: res, name: file.name.split('.')[0] }
                        },this.start)
                    }, 400)
                }
            } catch (error) {
                this.loading = false
            }
        }
    }
}
</script>